// Import packages
import React, { Component } from "react";
import { Route, Routes, Redirect, Navigate, } from "react-router-dom";
import { store } from "../redux/store";
import {} from "react-router-dom";
// Import utils
import { history } from "../configs/history";

// Import pages
import Layout from "../containers/Layout";
import Homepage from "../containers/homepage/Homepage";
import Contact from "../containers/contact/Contact";
import NotFound from "../containers/not-found/NotFound";


const locales = ['hy', 'en', 'ru']
export default class MainRoutes extends Component {
    render () {
        return <Layout>
            <Routes>
                <Route path={"/:locale?/*"} element={<ChildrenRoutes/>}/>
            </Routes>
        </Layout>
    }
}

 function ChildrenRoutes(){
        // const { location } = this.props
        // console.log('location', location)
        // const path = location?.pathname
        // const { selectedLanguage, languages } = store.getState().general || {}
        // const selectedCode = selectedLanguage?.code || languages?.find(lg => lg.isMain)?.code || 'hy'
        // const actualLocals = (languages && languages.length && languages.map(lg => lg.code)) || locales
        // if (!path || path === '/') {
        //     history.push(`/${selectedCode}`)
        //     return null
        // }
        // let locale = path.split('/')[1]
        //
        //
        // if (!actualLocals.includes(locale)) {
        //     const newPath = `/${selectedCode}${path}`
        //     history.push(newPath)
        //     return null
        // }


        const locale= 'hy'
        return <Routes>
            <Route path="/" element={ <Navigate to={`/${locale}/home`} replace />} />
            <Route  path={`/home`} element={<Homepage/>}/>
            <Route  path={`/contact`} element={<Contact/>}/>
            {/*<Route path={`/${locale}/custom-page/:slug`} component={CustomPage}/>*/}
            <Route  path={`/404`} element={<NotFound/>}/>
            <Route  path={'*'} element={<NotFound/>}/>
        </Routes>

}
