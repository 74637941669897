// import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from 'react-router-dom'

// Import assets
import "./about.scss"
import {SliderArrowIcon} from "../../../assets/images";

// Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {generateFileMediaUrl} from "../../../utils/generateMediaUrl";
import {getUrlWithLocal} from "../../../utils/getUrlWithLocal";


class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStorage: {},
            slideIndex: 0
        }
    }


    render() {
        const {about, staticTexts} = this.props

        return <div className={'HomepageAbout'}>
            <div className={'HomepageAboutContent'}>
                <div className="FeaturesWrapper">
                    <h2 className={'features-title'}>{about.title}</h2>
                    <div className={'features-text'} dangerouslySetInnerHTML={{__html: about.description}}/>
                    <div className={'features-bottom-content'}>
                        <div className={'features'}>
                            {about?.features && about?.features?.length && about.features.map(item => {
                                return <Link to={getUrlWithLocal('/about')} key={item.slug}
                                             className={'features-item'}>
                                    <img src={generateFileMediaUrl(item?.mediaMain?.path)} alt={""}/>
                                    <span>{item?.title}</span>
                                </Link>
                            })}
                        </div>
                        <div className="linkWrapper">
                            <span>{staticTexts?.homepage_about_questions_text}</span>
                            <Link to={getUrlWithLocal("/about")} className={'features-btn-see-all'}>{staticTexts?.homepage_about_btn_see_all} <SliderArrowIcon/></Link>
                        </div>
                    </div>
                </div>
                <div className={'About-img-wrapper'}>
                        <div className={'img-block'}>
                            <img src={generateFileMediaUrl(about?.backgroundImage?.path)} alt=""/>
                        </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'aboutSlider',
    'about'
]);

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(About);
