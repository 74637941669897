import '../../containers/comingSoon/comingSoon.scss'
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";
import {
    CollapseLangIcon, FacebookIcon,
    FooterFbIcon,
    FooterInctIcon, InstagramIcon,
    Logo,
    Mobile_img,
    PhoneDropDownIcon,
    Web_img, WhatsappIcon, WhatsUppIcon
} from "../../assets/images";
import {Link} from "react-router-dom";
import {generateFileMediaUrl} from "../../utils/generateMediaUrl";
import {Dropdown, Menu} from "antd";
import {history} from "../../configs/history";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {ChangeLanguage} from "../../redux/actions";
import {connect} from "react-redux";

function ComingSoon(props) {
    const {menu, contact, staticTexts, languages, selectedLanguage,} = props;
    const phone = contact?.phoneNumber?.replace(/\D/g, '').substring(0, 12)
    const areaCode = phone?.substring(0, 3);
    const code = phone?.substring(3, 5);
    const content = phone?.substring(5, 7);
    const content1 = phone?.substring(7, 9);
    const content2 = phone?.substring(9, 12);

    let title = staticTexts?.comingSoon_text1 || '%sНовый проект %s  для вашего отдыха в Цахкадзорe'
    const firstIndex = title.indexOf('%s')
    const lastIndex = title.lastIndexOf('%s')

    if (firstIndex !== -1 && lastIndex !== -1 && firstIndex !== lastIndex) {
        title = title.replace('%s', `${firstIndex === 0 ? "": '&nbsp;'}<span>`);
        title = title.replace('%s', `</span>&nbsp; `);
    }

    const menuLanguage = !!languages?.length && (
        <Menu className="languages-dropdown">
            {!!languages?.length && languages?.map((item) => {
                return selectedLanguage?.code !== item.code &&
                    <Menu.Item key={item.id}
                               onClick={() => {
                                   history.push(`/${item.code}${history.location.pathname.slice(3)}`)
                                   props.ChangeLanguage(item)
                               }}
                               className={'language-dropdown-item'}>
                        <div>{item.code}</div>
                    </Menu.Item>
            })
            }
        </Menu>
    );

    return <div className={'coming-soon-wrapper'}>
        <div className={'coming-soon-header'}>
            <div className={'logo-block'}>
                <img src={Logo} alt=""/>
            </div>
            <div className={'language-block'}>
                <Dropdown overlay={menuLanguage}
                          trigger={['hover']}
                          overlayClassName={'languages-wrapper'}
                          placement="bottom"
                          size={'small'}>
                    <div className="dropdown-menu-item">
                        <div>{selectedLanguage?.code}</div>
                        <CollapseLangIcon className={'arrow-icon'} title={''}/>
                    </div>
                </Dropdown>
            </div>
        </div>
        <div className={'middle-block'}>
            <img src={window.innerWidth > 600 ? Web_img : Mobile_img} alt=""/>
            <span className={'bg-color'}/>
            <div className={'info-block'} dangerouslySetInnerHTML={{__html: title}}/>

            {/* <div className={'info-block'}>
                <span>{staticTexts?.comingSoon_text1 || 'Новый проект'}</span> {staticTexts?.comingSoon_text2 || 'для вашего отдыха в Цахкадзорe'}
            </div>*/}
        </div>
        <div className={'footer-block'}>
            <div className={'phoneNumber-block'}>
                <div className={'phone-title'}>
                    {
                        staticTexts?.telephone_title || 'Телефон компании'
                    }
                </div>
                <a href={`tel:+${phone}`} target={'_blank'}>
                    <span>{`+${areaCode} (${code}) ${content} ${content1} ${content2}`}</span>
                </a>
            </div>
            <div className={'sociLinks-block'}>
                <a href={contact?.facebookUrl} target={'_blank'} rel="noreferrer"><FacebookIcon/></a>
                <a href={contact?.instagramUrl} target={'_blank'} rel="noreferrer"><InstagramIcon/></a>
                <a href={`https://wa.me/+${phone}`} target={'_blank'}><WhatsappIcon/></a>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'languages',
        'staticTexts',
        'contact',
        'selectedLanguage',
    ])
};

const mapDispatchToProps = {
    ChangeLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(ComingSoon);