import React from 'react';
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from "react-redux";
import {store, persistor} from "./redux/store";
import Routes from "./routing"
function App () {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <Routes/>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
}

export default App;
