
//--------------------------------------- Prod-server --------------------------------------------
const PROD_URLS = {
    ADMIN_URL: "https://app.parkline.am/admin/api/v1",
    BASE_URL: "https://app.parkline.am/front/api/v1",
    HOST_MEDIA_URL: "https://app.parkline.am/admin",
}

//--------------------------------------- Dev-server --------------------------------------------

const DEV_URLS = {
    ADMIN_URL: "http://173.212.233.220:9200/api/v1",
    BASE_URL: "http://173.212.233.220:9210/api/v1",
    HOST_MEDIA_URL: "http://173.212.233.220:9200",
}
const {BASE_URL, ADMIN_URL, HOST_MEDIA_URL} =
    process.env.REACT_APP_WEB_ENVIRONMENT === 'production' ? PROD_URLS : DEV_URLS;


export const _hostMedia = HOST_MEDIA_URL;
export const _urlLanguage = BASE_URL + "/languages";
export const _urlStaticTexts = ADMIN_URL + "/static-texts";
export const _urlMenu = BASE_URL + "/menu";
export const _urlBadges = BASE_URL + "/badges";
export const _urlBanks = BASE_URL + "/banks";
export const _urlSlider = BASE_URL + "/slider";
export const _urlAbout = BASE_URL + "/about";
export const _urlContact = BASE_URL + "/contact";
export const _urlBuildings = BASE_URL + "/buildings";
export const _urlFloors = BASE_URL + "/floors";
export const _urlAreas = BASE_URL + "/areas";
export const _urlParking = BASE_URL + "/parking";
export const _urlReservation = BASE_URL + "/reservation";
export const _urlSubscribers = BASE_URL + "/subscribers";
export const _urlAboutSlider = BASE_URL + "/about-slider";
export const _urlProject = BASE_URL + '/projects';
export const _urlCallRequest = BASE_URL + '/call-request';
export const _urlRateList = BASE_URL + '/rates-list';

